import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Container, Row, Col } from "react-bootstrap"
import "./ResearchDetails.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import { inViewOptions } from "../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import logoBlack from "../../images/logo.svg"
import NewsBanner from "@Components/Static/NewsBanner/NewsBanner"
import ContactSidebarNews from "@Components/Static/ContactSidebar/ContactSidebarNews"
import OpinionList from "@Components/Static/OpinionList/OpinionList"
import ReactMarkdown from "react-markdown"
import PlayVideo from "../../Components/PlayVideo/PlayVideo"
import RelatedResearch from "@Components/StaticPageTwo/RelatedResearch/RelatedResearch"
// import { Document, Page } from 'react-pdf/dist/umd/entry.parcel';
// import { PDFViewer } from 'react-view-pdf';
import Modal from "react-bootstrap/Modal"
import RegisterForm from "@Components/forms/registerview"
import NoImage from "../../images/no-image.png"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../../pages/404"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import TeamForm from "@Components/forms/teamcontact"
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const ResearchDetails = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const [showEmail, setShowEmail] = useState(false)
  const handleCloseEmail = () => setShowEmail(false)
  const handleShowEmail = event => {
    setShowEmail(true)
  }
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [isClient, setClient] = useState(false)
  // useEffect(() => {

  //   setClient(true);
  // }, [])
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  const GET_RESEARCH_DETAILS = gql`
    query GetResearch($URL: String!) {
      researchs(where: { URL: $URL }) {
        id
        imagetransforms
        Content
        Date
        Designation
        Email
        Iframe
        Meta_Description
        Meta_Title
        Name
        Phone
        Publish
        Video_Url
        URL
        Title
        Image {
          alternativeText
          url
        }
        Tail_Image {
          alternativeText
          url
        }
        Research_Image {
          alternativeText
          url
        }
        File {
          alternativeText
          url
        }
      }
    }
  `
  const { loading, error, data } = useQuery(GET_RESEARCH_DETAILS, {
    variables: { URL: props.slug },
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  const [isPlay, setIsPlay] = useState(false)
  // const viewReport = () => {
  //   setClient(!isClient)
  //   setShow(true)
  //   myRef.current.scrollIntoView({ behavior: "smooth" })
  // }
  const viewReport = () => {
    handleShow()
  }

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  const currentOrigin = typeof window !== undefined && window.location.origin
  const branchListPage = `${currentOrigin}/contact-dng-estate-agents/branch-finder`

  return data?.researchs?.length > 0 ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout className="blog-page" Alias={"research"}>
                {data?.researchs?.map((Page, i) => {
                  let processedImages = JSON.stringify({})
                  if (Page?.imagetransforms?.Image_Transforms) {
                    processedImages = Page.imagetransforms.Image_Transforms
                  }

                  let processedImages1 = JSON.stringify({})
                  if (Page?.imagetransforms?.Research_Image_Transforms) {
                    processedImages1 = Page.imagetransforms.Research_Image_Transforms
                  }

                  

                  return (
                    <>
                      <SEO
                        title={Page ? Page.Title : ""}
                        description={
                          Page
                            ? "Read about " +
                              Page.Title +
                              " here, subscribe now and stay updated for all latest property news."
                            : ""
                        }
                        image={
                          Page?.Research_Image?.url
                            ? Page.Research_Image.url
                            : null
                        }
                      />
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template research-page`,
                        }}
                      />
                      <Breadcrumbs
                        Page={Page?.Title}
                        class="static"
                        alias={"research"}
                        type="details-page"
                        typenew="details-page-research"
                      />

                      <div className="static-block">
                        <Container>
                          <Row>
                            <Col xl="7" className="col-xxl-8 col-custom-8">
                              <h1>{Page.Title}</h1>
                              {Page?.Image?.url &&
                                Page?.Name &&
                                Page?.Designation &&
                                Page?.Phone &&
                                Page?.Email && (
                                  <div className="dealer-wrap research-details">
                                    <div className="img-wrap">
                                      {Page?.Image?.url && (
                                        <ImageTransform
                                          imagesources={Page?.Image?.url}
                                          renderer="srcSet"
                                          imagename="researchs.Image.detail"
                                          attr={{
                                            alt: Page.Image?.alternativeText
                                              ? Page.Image?.alternativeText
                                              : Page?.Name +
                                                " - DNG Estate Agents",
                                            className: "",
                                          }}
                                          imagetransformresult={processedImages}
                                          id={Page.id}
                                        ></ImageTransform>
                                      )}
                                    </div>
                                    <div className="dealer-right">
                                      <span className="dealer-name">
                                        {Page?.Name}
                                      </span>
                                      <span className="dealer-position">
                                        {Page?.Designation}
                                      </span>
                                      <div className="person-contact">
                                        {Page?.Phone && (
                                          <Link
                                            href={"tel:" + Page?.Phone}
                                            className="phone-number"
                                          >
                                            <i className="icon-phone"></i>{" "}
                                            {Page?.Phone}
                                          </Link>
                                        )}
                                        {Page?.Email && (
                                          <a
                                            href="javascript:;"
                                            onClick={() => handleShowEmail()}
                                            className="mail-text d-none d-xl-flex"
                                          >
                                            <i className="icon-mail"></i>
                                          </a>
                                        )}
                                        <Modal
                                          show={showEmail}
                                          centered={true}
                                          onHide={handleCloseEmail}
                                          backdrop="static"
                                          keyboard={false}
                                          dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                        >
                                          <Modal.Header
                                            closeButton
                                            className="contact-close-btn"
                                          >
                                            <Modal.Title className="w-100">
                                              Email to {Page?.Name}
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body className="form">
                                            <TeamForm
                                              toemail={Page?.Email}
                                              officeNumber={Page?.Phone}
                                              teamMember={Page?.Name}
                                            />
                                          </Modal.Body>
                                        </Modal>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <div className="dealer-cta d-xl-flex bottom-fixed-btn">
                                {(Page.File?.url || Page.Iframe) && (
                                  <div className="btn-wrap">
                                    {isClient ? (
                                      <a
                                        onClick={() => setClient(false)}
                                        className="btn"
                                      >
                                        {isClient ? "view" : "view"} report
                                      </a>
                                    ) : (
                                      <a onClick={viewReport} className="btn">
                                        {isClient ? "view" : "view"} report
                                      </a>
                                    )}
                                  </div>
                                )}
                                {Page?.Video_Url && (
                                  <div className="btn-wrap">
                                    <a
                                      onClick={playVideoHandler}
                                      className="btn outline-btn"
                                    >
                                      <i className="icon-play"></i> play video
                                    </a>
                                  </div>
                                )}
                                <Modal
                                  show={show}
                                  centered={true}
                                  onHide={handleClose}
                                  backdrop="static"
                                  keyboard={false}
                                  dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                >
                                  <Modal.Header
                                    closeButton
                                    className="contact-close-btn"
                                  >
                                    <Modal.Title className="w-100">
                                      <sapn className="highlight-text">
                                        register
                                      </sapn>{" "}
                                      to view
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body className="form">
                                    <p>
                                      We’ll never share your details with
                                      anyone.
                                    </p>
                                    <RegisterForm
                                      shareurl={shareurl}
                                      Title={Page.Title}
                                      show={show}
                                      setShow={setShow}
                                      isClient={isClient}
                                      setClient={setClient}
                                      executeScroll={executeScroll}
                                      handleClose={handleClose}
                                      branchListPage={branchListPage}
                                    />
                                  </Modal.Body>
                                </Modal>
                              </div>
                              <motion.div className="ContentBlock">
                                <ReactMarkdown
                                  source={Page.Content}
                                  allowDangerousHtml
                                />
                              </motion.div>
                              <PlayVideo
                                isOpen={isPlay}
                                stopPlay={setIsPlay}
                                videoId={Page.Video_Url}
                                isAutoPlay={1}
                              />
                              {/* {Page.File?.url} */}
                            </Col>
                            <Col xl="5" className="col-xxl-4 col-custom-4">
                              {Page?.Research_Image?.url ? (
                                <ImageTransform
                                  imagesources={Page?.Research_Image?.url}
                                  renderer="srcSet"
                                  imagename="researchs.Research_Image.detail"
                                  attr={{
                                    alt: Page.Research_Image?.alternativeText
                                      ? Page.Research_Image?.alternativeText
                                      : Page?.Name + " - DNG Estate Agents",
                                    className: "right-img-view",
                                  }}
                                  imagetransformresult={processedImages1}
                                  id={Page.id}
                                ></ImageTransform>
                              ) : (
                                <img
                                  src={NoImage}
                                  alt={Page.Research_Image?.alternativeText}
                                />
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12" ref={myRef}>
                              {
                                isClient &&
                                  (Page.File?.url || Page?.Iframe) && (
                                    <iframe
                                      className="iframe-top"
                                      src={
                                        Page.File?.url
                                          ? Page.File.url
                                          : Page.Iframe
                                      }
                                      allowfullscreen="true"
                                      allow="fullscree"
                                      style={{ width: "100%", height: "600px" }}
                                    ></iframe>
                                  )
                                // <PDFViewer url={Page.File?.url} />
                              }
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </>
                  )
                })}
                <SubscribeForm type="Research" />
                <RelatedResearch CurrentNews={data?.researchs[0]?.Title} />
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
}

export default ResearchDetails
